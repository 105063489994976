import React, { useEffect, useRef, useState } from 'react'

import { useApp } from '@/core/contexts/app'

import Image from '@/components/base/image/Image'
import { Column, Container, Row } from '@/components/base/gridview'

import { BannerProps } from './types'
import Video from '@/components/base/video'
import { useWidth } from '@/core/hooks/useWidthResize'
import Button from '@/components/base/button/Button'
import { classnames } from 'begonya/functions'
import Icon from '@/components/base/icon'

const Banner: React.FunctionComponent<BannerProps> = ({
  image,
  video,
  videoMobile,
}) => {
  const app = useApp()
  const width = useWidth()

  const videoRef = useRef<HTMLVideoElement | null>(null)

  const [playButton, setPlayButton] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)

  const videoSettings = {
    autoPlay: false,
    controls: false,
    loop: true,
    playsInline: true,
    preload: 'auto',
  }

  const togglePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play()
        setIsPlaying(true)
      } else {
        videoRef.current.pause()
        setIsPlaying(false)
      }
    }
  }

  return (
    <section className="banner">
      <Container size="fluid">
        <Row>
          <Column xs={{ size: 12, padding: 0 }}>
            {image && (
              <div className={'banner-image'}>
                <Image fluid {...image} alt={app.settings.title} />
              </div>
            )}
            {video && (
              <div className="banner-video">
                <Video
                  {...(width > 1025 ? video : videoMobile)}
                  ref={videoRef}
                  {...videoSettings}
                  controls={false}
                />

                <Button
                  className={classnames(
                    'banner-video-button',
                    isPlaying ? 'banner-video-button--close' : ''
                  )}
                  onClick={() => togglePlay()}
                >
                  {isPlaying ? (
                    <Icon name="pause" />
                  ) : (
                    <Icon name="play-arrow" />
                  )}
                </Button>
              </div>
            )}
          </Column>
        </Row>
      </Container>
    </section>
  )
}

export default Banner
